import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Dashnav/Navbar";
import Header from "./Dashnav/Header";
import { Link, Route, useHistory } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";
import axios from "axios";
import emailjs from "emailjs-com";

function Transactions() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const history = useHistory();
  const [dataready, setdataready] = useState(false);
  const [{ userdetails, loggedin, tradingpair }, dispatch] =
  useContext(GlobalContext);




  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null); // to store the authenticated user

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user); // Set the authenticated user
        await fetchTransactions(user.uid); // Fetch transactions when the user is authenticated
      } else {
        setUser(null); // Set user to null if not authenticated
        Swal.fire("Not Logged In", "You need to log in first.", "error");
      }
    });

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  const fetchTransactions = async (userId) => {
    try {
      const db = firebase.firestore();
      
      // Fetch transactions for the logged-in user
      const transactionsSnapshot = await db
        .collection("transactions")
        .where("userId", "==", userId)
        .orderBy("date", "desc") // Sorting by date descending
        .get();

      const fetchedTransactions = transactionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setTransactions(fetchedTransactions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      Swal.fire("Error", "There was an issue fetching transactions.", "error");
      setLoading(false);
    }
  };

  // Function to render transaction status buttons dynamically
  const renderStatusButton = (status) => {
    let buttonClass = "btn btn-secondary btn-sm";
    let buttonText = status;

    if (status === "Completed") {
      buttonClass = "btn btn-primary btn-sm";
      buttonText = "Completed";
    } else if (status === "Processing") {
      buttonClass = "btn btn-warning btn-sm";
      buttonText = "Processing";
    } else if (status === "Pending") {
      buttonClass = "btn btn-info btn-sm";
      buttonText = "Pending";
    }

    return (
      <input type="button" className={buttonClass} value={buttonText} style={{ textAlign: "center" }} />
    );
  };


  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setLoading(false);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
        } else {
          setloggedin(false);
          setLoading(false);
          history("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    const fetching = await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setLoading(false);
          setdataready(true);
        } else {
          console.log("No such document!");
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    global.window && (global.window.location.href = "/home.html");
    const let3 = await history.push("/");
  };

  return (
    <div>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=0.75, shrink-to-fit=no"
      />
      <meta name="description" content />
      <meta name="author" content />
      <link rel="shortcut icon" href="img/favicon.png" type="image/x-icon" />
      {/* <title>TradingFxCapital | Dashboard</title> */}
      <link
        rel="stylesheet"
        href="vendor/font-awesome-4.7.0/css/font-awesome.min.css"
        type="text/css"
      />
      <link
        rel="stylesheet"
        href="vendor/bootstrap-4.1.1/css/bootstrap.css"
        type="text/css"
      />
      <link
        href="vendor/datatables/css/dataTables.bootstrap4.css"
        rel="stylesheet"
      />
      <link
        href="vendor/datatables/css/responsive.dataTables.min.css"
        rel="stylesheet"
      />
      <link
        href="vendor/jquery-jvectormap/jquery-jvectormap-2.0.3.css"
        rel="stylesheet"
      />
      <link rel="stylesheet" href="css/dark_grey_adminux.css" type="text/css" />
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
      />
      {/* g-hide */}
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.goog-te-banner-frame {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    iframe.skiptranslate {\n      display: none !important;\n    }\n  ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    body {\n      position: static !important;\n      top: 0px !important;\n    }\n  ",
        }}
      />
      {/* end-g-hide */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.alert {\n      padding: 4px 16px;\n      background: white;\n    }\n\n    input:text {\n      color: white;\n    }\n  ",
        }}
      />
      {/* /GetButton.io widget*/}
      {/* /GetButton.io widget */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    div.scrollmenu {\n      background-color: #333;\n      overflow: auto;\n      white-space: nowrap;\n    }\n\n    div.scrollmenu a {\n      display: inline-block;\n      color: white;\n      text-align: center;\n      padding: 14px;\n      text-decoration: none;\n    }\n\n    div.scrollmenu a:hover {\n      background-color: #777;\n    }\n\n    table,\n    td {\n      background: black;\n      color: white;\n    }\n\n    th {\n      background: #00000088;\n      color: white;\n    }\n  ",
        }}
      />
      <header className="navbar-fixed">
        <nav className="navbar navbar-toggleable-md navbar-inverse bg-faded">
          {/* Header can be reused across pages */}
          <Header onToggleSidebar={toggleSidebar} />
          {/* Sidebar */}
          <Navbar isVisible={isSidebarVisible} activePage={"Transactions"} />
          <div className="d-flex mr-auto"> &nbsp;</div>
          <div id="google_translate_element" style={{ marginBottom: '20px' }}></div>
          <div className="sidebar-right pull-right ">
            <ul className="navbar-nav  justify-content-end">
              <li className="nav-item">
                <button
                  className="btn-link btn userprofile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <span className="userpic">
                  <img src="https://img.icons8.com/?size=80&id=108652&format=png" alt="user pic" />
                </span>{" "}
                <span className="text">offic</span>
              </li>
              <li>
                 <a onClick={logout}className="btn btn-link icon-header">
                  <span className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="wrapper-content">
      <div className="container" style={{ maxWidth: "1300px" }}>
        <title>TradingFxCapital | Transactions</title>
        <h2 style={{ textAlign: "center" }}>Transactions</h2>
        <br />
        <span>
          <div className="scrollmenu">
            <table className="table" cellSpacing={400} table-bordered>
              <tbody>
                <tr>
                  <th>TYPE</th>
                  <th>STATUS</th>
                  <th>AMOUNT</th>
                  <th>DATE</th>
                </tr>

                {loading ? (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      Loading transactions...
                    </td>
                  </tr>
                ) : (
                  transactions.map((transaction) => (
                    <tr key={transaction.id}>
                      <td>{transaction.type}</td>
                      <td>{renderStatusButton(transaction.status)}</td>
                      <td>${transaction.amount}</td>
                      <td>{new Date(transaction.date.seconds * 1000).toLocaleDateString()}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </span>
        <hr />
        <a href="dashboards">
          <button className="btn btn-dark" style={{ color: "white" }}>
            <i className="fa fa-undo" /> Back to Account
          </button>
        </a>
        <br />
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="form-group row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-primary"
                  // onClick={() => navigator.clipboard.writeText({`https://www.finance-tellars.com/register.html?${userdetails.myReferralId}`})}
                >
                  Copy Referral Link
                </button>
              </div>
              <div className="col-10">
                <input
                  type="text"
                  className="form-control"
                  id="referral_link"
                  defaultValue={`https://www.finance-tellars.com/register.html?${userdetails.myReferralId}`}
                  readOnly
                />
              </div>
            </div>
          </div>
          {/* TradingView Widget BEGIN */}
          <div className="tradingview-widget-container">
            <div className="tradingview-widget-container__widget" />
          </div>
          {/* TradingView Widget END */}

          <div className="mgm" style={{ display: "none" }}>
            <div className="txt" style={{ color: "black" }} />
          </div>
        </div>
      </div>
    </div>


      <div
        id="google_translate_element"
        style={{ visibility: "visible", display: "none" }}
      />
      {/* TradingView Widget BEGIN */}
      <div className="sidebar-right tradingview-widget-container ">
        <iframe
          allowTransparency="true"
          style={{ boxSizing: "border-box", height: "100%", width: "100%" }}
          src="https://www.tradingview-widget.com/embed-widget/screener/?locale=en#%7B%22width%22%3A%22220%22%2C%22height%22%3A600%2C%22defaultColumn%22%3A%22overview%22%2C%22defaultScreen%22%3A%22general%22%2C%22market%22%3A%22forex%22%2C%22showToolbar%22%3Afalse%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22enableScrolling%22%3Atrue%2C%22utm_source%22%3A%22marketstockspro.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22forexscreener%22%7D"
          frameBorder={0}
        />
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n    .tradingview-widget-copyright {\n      font-size: 13px !important;\n      line-height: 32px !important;\n      text-align: center !important;\n      vertical-align: middle !important;\n      font-family: 'Trebuchet MS', Arial, sans-serif !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright .blue-text {\n      color: #2962FF !important;\n    }\n\n    .tradingview-widget-copyright a {\n      text-decoration: none !important;\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:visited {\n      color: #9db2bd !important;\n    }\n\n    .tradingview-widget-copyright a:hover .blue-text {\n      color: #1E53E5 !important;\n    }\n\n    .tradingview-widget-copyright a:active .blue-text {\n      color: #1848CC !important;\n    }\n\n    .tradingview-widget-copyright a:visited .blue-text {\n      color: #2962FF !important;\n    }\n  ",
          }}
        />
      </div>
      {/* TradingView Widget END */}
    </div>
  );
}

export default Transactions;
