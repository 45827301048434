import React, { useEffect, useRef, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import Navbar from "./Dashnav/Navbar";
import Header from "./Dashnav/Header";
import { Link, Route, useHistory } from "react-router-dom";
import { f, database, storage, auth } from "../config";
import db from "../config";
import { GlobalContext } from "../Globalstate";
import firebase from "firebase";
import Swal from "sweetalert2";

function Dashboard() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  const [{ userdetails, loggedin, tradingpair }, dispatch] =
    useContext(GlobalContext);
  const [loading, setloading] = useState(true);
  const [dataready, setdataready] = useState(false);

  const [profits, setprofits] = useState(0);

  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  function cleanDate(d) {
    var date = new Date(d);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    console.log(
      "Date: " +
        date.getDate() +
        "/" +
        months[date.getMonth() + 1] +
        "/" +
        date.getFullYear() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds()
    );
    return `${date.getDate()} ${
      months[date.getMonth()]
    } ${date.getFullYear()}  ${date.getHours()}  ${date.getMinutes()} ${date.getSeconds()}`;
  }

  const history = useHistory();
  useEffect(() => {
    if (loggedin) {
      console.log(userdetails);
      console.log(userdetails.email);
      setOpen(!open);
      console.log(userdetails.totalearnings);
    } else {
      f.auth().onAuthStateChanged(function (user) {
        if (user) {
          var userid = f.auth().currentUser;
          var userids = userid.uid;
          fetchuserdata(userids);
          setloggedin(true);
          setloading(false);
        } else {
          setloggedin(false);
          setOpen(!open);
          history.push("/");
        }
      });
    }
  }, []);

  const fetchuserdata = async (userid) => {
    var docRef = db.collection("users").doc(userid);
    await docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setdetails(doc.data());
          console.log(doc.data());
          setloggedin(true);
          setOpen(!open);
          setdataready(true);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  };

  const setdetails = (data) => {
    dispatch({ type: "setuserdetails", snippet: data });
  };

  const setloggedin = (data) => {
    dispatch({ type: "setloggedin", snippet: data });
  };

  const logout = async () => {
    const let1 = await setloggedin(false);
    const let2 = await f.auth().signOut();
    global.window && (global.window.location.href = "/home.html");
    const let3 = await history.push("/");
  };

  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK//////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////

  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(0);
  const [investments, setInvestments] = useState([]);

  useEffect(() => {
    // Fetch user and investment data when the component loads
    auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        // Fetch user details
        const userDoc = await db.collection("users").doc(currentUser.uid).get();
        setBalance(userDoc.data().balance || 0);

        // Fetch investments
        const investmentsSnapshot = await db
          .collection("Investments")
          .where("active", "==", true) // Add condition for active investments
          .where("userId", "==", currentUser.uid)
          .get();
        const investmentData = investmentsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setInvestments(investmentData);

        // Calculate daily earnings
        calculateDailyEarnings(investmentData, currentUser.uid);
      }
    });
  }, []);

  const calculateDailyEarnings = async (investments, userId) => {
    const dailyPercentages = {
      "Rudiments Plan": 0.03,
      "Gold Plan": 0.04,
      "Antminer Plan": 0.04,
      "Antminer Pro Plan": 0.045,
      "Innosilicon Plan": 0.05,
      "Variable Plan": 0.08,
      "Variable Pro Plan": 0.089,
      "VIP Plan": 0.1,
      "VIP Pro Plan": 0.12,
      "Shareholder Plan": 0.3,
      "Shareholder Pro Plan": 0.5,
    };
  
    let totalEarnings = 0;
    let daysToUse = 0;
  
    const serverTime = firebase.firestore.Timestamp.now();
  
    const updates = investments.map(async (investment) => {
      const { id, amount, investmentPaidFor, lastUpdated } = investment;
  
      // Convert lastUpdated to Firebase Timestamp
      let lastUpdatedDate;
      if (lastUpdated) {
        lastUpdatedDate = lastUpdated.toDate(); // Convert Firestore Timestamp to JavaScript Date
      } else {
        // If lastUpdated is missing, set it to the current server time
        lastUpdatedDate = serverTime.toDate();
        await db.collection("Investments").doc(id).update({
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        });
        return; // Skip further processing for new investments
      }
  
      console.log("Last Updated (Raw):", lastUpdated);
      console.log("Last Updated (Date):", lastUpdatedDate);
  
      // Skip if already updated today
      if (lastUpdatedDate.toDateString() === serverTime.toDate().toDateString()) {
        console.log("Already updated today; skipping.");
        return;
      }
  
      // Calculate the number of days since lastUpdated
      const daysSinceLastUpdate = Math.floor(
        (serverTime.toDate() - lastUpdatedDate) / (1000 * 60 * 60 * 24)
      );
      daysToUse = daysSinceLastUpdate;
  
      console.log(`Days Since Last Update: ${daysSinceLastUpdate}`);
  
      // Get daily percentage
      const dailyPercentage = dailyPercentages[investmentPaidFor];
      if (!dailyPercentage) return;
  
      // Accumulate earnings for the number of days
      const dailyEarnings = amount * dailyPercentage;
      const totalDaysEarnings = dailyEarnings * daysSinceLastUpdate;
      totalEarnings += totalDaysEarnings;
  
      console.log(`Daily Earnings: $${dailyEarnings}`);
      console.log(`Total Earnings for ${daysSinceLastUpdate} days: $${totalDaysEarnings}`);
  
      // Update lastUpdated in Firestore after earnings calculation
      await db.collection("Investments").doc(id).update({
        lastUpdated: firebase.firestore.FieldValue.serverTimestamp(), // Use server timestamp
      });
    });
  
    await Promise.all(updates);
  
    // Update user's balance in Firestore
    if (totalEarnings > 0) {
      const userDoc = await db.collection("users").doc(userId).get();
      const userData = userDoc.data();
      const updatedBalance = (userData.balance || 0) + totalEarnings;
      const updatedTotalEarnings = parseFloat(userData.totalearnings || 0) + totalEarnings;
  
      await db.collection("users").doc(userId).update({
        balance: updatedBalance,
        totalearnings: updatedTotalEarnings,
      });
  
      setBalance(updatedBalance);
  
      Swal.fire(
        "Daily Earnings Added",
        `You received $${totalEarnings.toFixed(2)} over ${daysToUse} days!`,
        "success"
      );
    }
  };
  

  // const calculateDailyEarnings = async (investments, userId) => {
  //   const dailyPercentages = {
  //     "Rudiments Plan": 0.03,
  //     "Gold Plan": 0.04,
  //     "Antminer Plan": 0.04,
  //     "Antminer Pro Plan": 0.045,
  //     "Innosilicon Plan": 0.05,
  //     "Variable Plan": 0.08,
  //     "Variable Pro Plan": 0.089,
  //     "VIP Plan": 0.1,
  //     "VIP Pro Plan": 0.12,
  //     "Shareholder Plan": 0.3,
  //     "Shareholder Pro Plan": 0.5,
  //   };

  //   let totalEarnings = 0;
  //   let daysToUse = 0;

  //   const updates = investments.map(async (investment) => {
  //     const { id, amount, investmentPaidFor, lastUpdated } = investment;

  //     const now = new Date(); // Current time

  //     let lastUpdatedDate;
  //     if (lastUpdated) {
  //       lastUpdatedDate = new Date(lastUpdated.seconds * 1000); // Convert Firestore timestamp
  //     } else {
  //       // If lastUpdated is missing, set it to the current time
  //       lastUpdatedDate = now;
  //       await db.collection("Investments").doc(id).update({
  //         lastUpdated: lastUpdatedDate, // Initialize `lastUpdated` in Firestore
  //       });
  //       return; // Skip processing for new investments
  //     }

  //     console.log("Now (Raw):", now);
  //     console.log("Last Updated (Converted):", lastUpdatedDate);
  //     console.log("Now Num", now.getTime());
  //     console.log("Last Update Num", lastUpdatedDate.getTime());
  //     // Compare lastUpdatedDate with now
  //     if (now.getTime() <= lastUpdatedDate.getTime()) {
  //       console.log("Already updated; skipping.");
  //       return;
  //     }

  //     // Calculate the difference in milliseconds
  //     const millisecondsSinceLastUpdate = now - lastUpdatedDate;

  //     // Convert milliseconds to full days
  //     const daysSinceLastUpdate =
  //       millisecondsSinceLastUpdate / (1000 * 60 * 60 * 24);
  //     daysToUse = Math.floor(daysSinceLastUpdate); // Use only whole days
  //     console.log(
  //       `Days Since Last Update: ${daysSinceLastUpdate} (Rounded: ${daysToUse})`
  //     );

  //     // Get daily percentage for the investment plan
  //     const dailyPercentage = dailyPercentages[investmentPaidFor];
  //     if (!dailyPercentage) return;

  //     // Calculate total earnings
  //     const dailyEarnings = amount * dailyPercentage;
  //     const totalDaysEarnings = dailyEarnings * daysToUse;
  //     totalEarnings += totalDaysEarnings;

  //     console.log(`Daily Earnings: $${dailyEarnings}`);
  //     console.log(
  //       `Total Earnings for ${daysToUse} days: $${totalDaysEarnings}`
  //     );

  //     // Update lastUpdated in Firestore to the current time
  //     await db.collection("Investments").doc(id).update({
  //       lastUpdated: now, // Update to the current timestamp
  //     });
  //   });

  //   await Promise.all(updates);

  //   // Update user's balance in Firestore
  //   if (totalEarnings > 0) {
  //     const userDoc = await db.collection("users").doc(userId).get();
  //     const updatedBalance = userDoc.data().balance + totalEarnings;
  //     const updatedTotalEarnings =
  //       parseFloat(userDoc.data().totalearnings || 0) + totalEarnings;

  //     await db.collection("users").doc(userId).update({
  //       balance: updatedBalance,
  //       totalearnings: updatedTotalEarnings,
  //     });

  //     Swal.fire(
  //       "Daily Earnings Added",
  //       `You received $${totalEarnings.toFixed(2)} over ${daysToUse} days!`,
  //       "success"
  //     );
  //   }
  // };

  // const calculateDailyEarnings = async (investments, userId) => {
  //   const dailyPercentages = {
  //     "Rudiments Plan": 0.03,
  //     "Gold Plan": 0.04,
  //     "Antminer Plan": 0.04,
  //     "Antminer Pro Plan": 0.045,
  //     "Innosilicon Plan": 0.05,
  //     "Variable Plan": 0.08,
  //     "Variable Pro Plan": 0.089,
  //     "VIP Plan": 0.1,
  //     "VIP Pro Plan": 0.12,
  //     "Shareholder Plan": 0.3,
  //     "Shareholder Pro Plan": 0.5,
  //   };

  //   let totalEarnings = 0;
  //   let daysToUse=0

  //   const updates = investments.map(async (investment) => {
  //     const { id, amount, investmentPaidFor, lastUpdated } = investment;

  //     const today = new Date();
  //     const todayDateString = today.toDateString();

  //     // Check if lastUpdated exists; if not, set it to the current date
  //     let lastUpdatedDate;
  //     if (lastUpdated) {
  //       lastUpdatedDate = new Date(lastUpdated.seconds * 1000); // Convert seconds to milliseconds
  //     } else {
  //       // If lastUpdated is missing, set it to the current date
  //       lastUpdatedDate = new Date();
  //       await db.collection("Investments").doc(id).update({
  //         lastUpdated: lastUpdatedDate, // Set lastUpdated for new investments
  //       });
  //     }

  //     console.log("Last Updated Raw", lastUpdated);
  //     console.log("Last Updated", lastUpdatedDate.toDateString());
  //     console.log("Today:", todayDateString);

  //     // Skip if already updated today
  //     if (lastUpdatedDate.toDateString() === todayDateString) {
  //       return;
  //     }

  //     // Calculate the number of days since lastUpdated
  //     const daysSinceLastUpdate = Math.floor(
  //       (today - lastUpdatedDate) / (1000 * 60 * 60 * 24)
  //     );
  //     daysToUse=daysSinceLastUpdate

  //     console.log(`Days Since Last Update: ${daysSinceLastUpdate}`);

  //     // Get daily percentage
  //     const dailyPercentage = dailyPercentages[investmentPaidFor];
  //     if (!dailyPercentage) return;

  //     // Accumulate earnings for the number of days
  //     const dailyEarnings = amount * dailyPercentage;
  //     const totalDaysEarnings = dailyEarnings * daysSinceLastUpdate;
  //     totalEarnings += totalDaysEarnings;

  //     console.log(`Daily Earnings: $${dailyEarnings}`);
  //     console.log(`Total Earnings for ${daysSinceLastUpdate} days: $${totalDaysEarnings}`);

  //     // Update lastUpdated in Firestore after earnings calculation
  //     await db.collection("Investments").doc(id).update({
  //       lastUpdated: today, // Set the current date as last updated
  //     });
  //   });

  //   await Promise.all(updates);

  //   // Update user's balance in Firestore
  //   if (totalEarnings > 0) {
  //     const userDoc = await db.collection("users").doc(userId).get();
  //     await db
  //       .collection("users")
  //       .doc(userId)
  //       .update({
  //         balance: userDoc.data().balance + totalEarnings,
  //         // totalearnings: parseFloat(userDoc.data().totalearnings) + totalEarnings,
  //         totalearnings: parseFloat(userDoc.data().totalearnings || 0) + totalEarnings,
  //       });
  //     setBalance(userDoc.data().balance + totalEarnings);

  //     Swal.fire(
  //       "Daily Earnings Added",
  //       `You received $${totalEarnings.toFixed(2)} over ${daysToUse} days!`,
  //       "success"
  //     );
  //   }
  // };

  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK//////////////////////////////////////////
  ///////////////////////////////////////INVESTMENT CHECK///////////////////////////////////////////

  return (
    <div>
      <meta name="ROBOTS" content="NOINDEX, NOFOLLOW" />
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=0.75, shrink-to-fit=no"
      />
      <meta name="description" content />
      <meta name="author" content />
      <link rel="shortcut icon" href="img/favicon.png" type="image/x-icon" />
      {/* <title>TradingFxCapital | Dashboard</title> */}

      {/* g-hide */}
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n      iframe.goog-te-banner-frame {\n        display: none !important;\n      }\n    ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n      iframe.skiptranslate {\n        display: none !important;\n      }\n    ",
        }}
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n      body {\n        position: static !important;\n        top: 0px !important;\n      }\n    ",
        }}
      />
      {/* end-g-hide */}
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n      div.alert {\n        padding: 4px 16px;\n        background: white;\n      }\n\n      input:text {\n        color: white;\n      }\n    ",
        }}
      />
      {/* /GetButton.io widget*/}
      {/* /GetButton.io widget */}
      <style
        data-styled="active"
        data-styled-version="5.3.11"
        dangerouslySetInnerHTML={{ __html: "" }}
      />
      <link
        type="text/css"
        rel="stylesheet"
        charSet="UTF-8"
        href="https://www.gstatic.com/_/translate_http/_/ss/k=translate_http.tr.26tY-h6gH9w.L.W.O/am=GAw/d=0/rs=AN8SPfoV6mMC6tlFnBTPsgfPv12vhvDMnA/m=el_main_css"
      />
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            '\n      .jqstooltip {\n        position: absolute;\n        left: 0px;\n        top: 0px;\n        visibility: hidden;\n        background: rgb(0, 0, 0) transparent;\n        background-color: rgba(0, 0, 0, 0.6);\n        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);\n        -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000)";\n        color: white;\n        font: 10px arial, san serif;\n        text-align: left;\n        white-space: nowrap;\n        padding: 5px;\n        border: 1px solid white;\n        z-index: 10000;\n      }\n      .jqsfield {\n        color: white;\n        font: 10px arial, san serif;\n        text-align: left;\n      }\n    ',
        }}
      />

      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n      div.scrollmenu {\n        background-color: #333;\n        overflow: auto;\n        white-space: nowrap;\n      }\n\n      div.scrollmenu a {\n        display: inline-block;\n        color: white;\n        text-align: center;\n        padding: 14px;\n        text-decoration: none;\n      }\n\n      div.scrollmenu a:hover {\n        background-color: #777;\n      }\n\n      table,\n      td {\n        background: black;\n        color: white;\n      }\n\n      th {\n        background: #00000088;\n        color: white;\n      }\n    ",
        }}
      />
      <header className="navbar-fixed">
        <nav className="navbar navbar-toggleable-md navbar-inverse bg-faded">
          {/* Header can be reused across pages */}
          <Header onToggleSidebar={toggleSidebar} />

          {/* Sidebar */}
          <Navbar isVisible={isSidebarVisible} activePage={"Account"} />

          <div className="d-flex mr-auto">&nbsp;</div>

          <div
            id="google_translate_element"
            style={{ marginBottom: "20px" }}
          ></div>

          <div className="sidebar-right pull-right">
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                <button
                  className="btn-link btn userprofile"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                />
                <span className="userpic">
                  <img
                    src="https://img.icons8.com/?size=80&id=108652&format=png"
                    alt="user pic"
                  />
                </span>
                <span className="text">offic</span>
              </li>
              <li>
                <a onClick={logout} className="btn btn-link icon-header">
                  <span className="fa fa-power-off" />
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      <div className="wrapper-content">
        <div className="container" style={{ maxWidth: "1300px" }}>
          {/* style="max-width:1400px" */}
          {/*<br><br> */}
          {/* <br> */}
          <title>Official {userdetails.fullname}</title>
          <div className="row align-items-end customer-profile-cover">
            <figure className="background">
              <img
                src="https://tradingfxcapital.com/Dashboard/img/kk2.gif"
                alt="Social cover image"
              />
            </figure>
            <div className="container mb-2">
              <br />
              <div
                className="row align-items-center p-2"
                style={{ marginTop: "-20px" }}
              >
                <figure className="social-profile-pic">
                  <a href="dashboards">
                    <img
                      src="https://img.icons8.com/?size=80&id=108652&format=png"
                      alt=""
                    />
                  </a>
                </figure>
                <div className="col-sm-16 col-lg-4 col-xl-4 profile-name">
                  <h2>
                    Official {userdetails.fullname}
                    <i style={{ color: "green" }} className="fa fa-star" />
                  </h2>
                  <p>{userdetails.country}</p>
                  <a href="deposit">
                    <button className="btn btn-success">Deposit</button>
                  </a>
                  <a href="withdraw">
                    <button className="btn btn-success ml-1">Withdraw</button>
                  </a>
                </div>
                <div className="col-16 col-sm-16 col-lg-9 col-xl-9 text-right d-flex">
                  <div className="col col-sm-8 col-lg col-xl-8">
                    <h4>Total Deposit</h4>
                    <h3>
                      <span className="text-warning">
                        ${userdetails.Totaldeposit}
                      </span>
                    </h3>
                    <h4>Total Withdrawal</h4>
                    <h3>
                      <span className="text-warning">
                        ${userdetails.Totalwithdrawal}
                      </span>
                    </h3>
                  </div>
                  <div className="col col-sm-8 col-lg col-xl-8">
                    <h4>Total Balance</h4>
                    <h3>
                      <span className="text-warning">
                        ${userdetails.balance}
                      </span>
                    </h3>
                    <h4>Total Earning</h4>
                    <h3>
                      <span className="text-warning">
                        ${userdetails.totalearnings}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* <div class="wrapper-content"> */}
          <div className="row" style={{ marginTop: "-20px" }}>
            <div className="col-md-16 col-lg-16 col-xl-16">
              <div className="activity-block success" id="bck">
                <div className="media">
                  <div className="media-body">
                    <a href="history">
                      <button type="button" className="btn btn-success">
                        <i className="fa fa-exchange" /> Trading History
                      </button>
                    </a>
                    <a href="settings">
                      <button
                        type="button"
                        style={{ float: "right" }}
                        className="btn btn-danger"
                      >
                        <i className="fa fa-gears" /> Settings
                      </button>
                    </a>
                    <hr />
                    <a href="transaction">
                      <button
                        type="button"
                        style={{ float: "center" }}
                        className="btn btn-success"
                      >
                        <i className="fa fa-exchange" /> View Transactions
                      </button>
                    </a>
                    <a href="signal">
                      <button
                        type="button"
                        style={{ float: "right" }}
                        className="btn btn-success"
                      >
                        <i className="fa fa-signal" /> Invest From Balance
                      </button>
                    </a>

                    {/* <br />
                    <hr />
                    <a
                      style={{
                        float: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Signal
                    </a>
                    <a
                      style={{
                        float: "right",
                        fontSize: "18px",
                        letterSpacing: "3px",
                      }}
                    >
                      {dataready && userdetails.activeSignal.signal}
                      {}
                    </a> */}
                    <br />
                    <hr />
                    <a
                      style={{
                        float: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Total Referrals
                    </a>
                    <a
                      style={{
                        float: "right",
                        fontSize: "18px",
                        letterSpacing: "1px",
                      }}
                    >
                      None
                    </a>
                    <br />
                    <hr />
                    <a
                      style={{
                        float: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Account Type
                    </a>
                    <a
                      style={{
                        float: "right",
                        fontSize: "18px",
                        letterSpacing: "1px",
                      }}
                    >
                      {userdetails.accountType}
                      <br />
                    </a>
                    <br />
                    <br />
                    <hr />
                    <a
                      style={{
                        float: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Account Status
                    </a>
                    <a
                      style={{
                        float: "right",
                        fontSize: "20px",
                        letterSpacing: "3px",
                      }}
                    >
                      {userdetails.verified ? (
                        <b
                          style={{
                            color: "white",
                            background: "#070", // Green background
                            padding: "4px 7px",
                          }}
                        >
                          Verified
                        </b>
                      ) : (
                        <b
                          style={{
                            color: "white",
                            background: "#700", // Red background
                            padding: "4px 7px",
                          }}
                        >
                          Not Verified
                        </b>
                      )}
                    </a>
                    <br />
                    <hr />
                    <a
                      style={{
                        float: "left",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      Package
                    </a>
                    <a
                      style={{
                        float: "right",
                        fontSize: "18px",
                        letterSpacing: "1px",
                      }}
                    >
                      <ul>
                        {investments.map((inv) => (
                          <li key={inv.id}>
                            {inv.investmentPaidFor}: ${inv.amount.toFixed(2)} -
                            Last Updated:{" "}
                            {inv.startDate
                              ? new Date(
                                  inv.startDate.seconds * 1000
                                ).toLocaleDateString()
                              : "Never"}
                          </li>
                        ))}
                      </ul>
                    </a>
                    <br />
                    <marquee />
                  </div>
                  <i className="fa ffa fa-" />
                </div>
                <br />
                <div className="row">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                      style={{ width: "39%" }}
                    >
                      <span className="trackerball" />
                    </div>
                  </div>
                </div>
                <i className="bg-icon text-center fa fa-user" />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="row" style={{ marginTop: "-50px" }}>
            <div className="col-md-16 col-lg-16 col-xl-16">
              {/* TradingView Widget BEGIN */}
              <div className="tradingview-widget-container">
                <div id="tradingview_e705a" style={{ height: "550px" }}>
                  <div
                    id="tradingview_db658-wrapper"
                    style={{
                      position: "relative",
                      boxSizing: "content-box",
                      margin: "0px auto !important",
                      padding: "0px !important",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <iframe
                      title="advanced chart TradingView widget"
                      lang="en"
                      id="tradingview_db658"
                      frameBorder={0}
                      allowTransparency="true"
                      scrolling="no"
                      allowFullScreen="true"
                      src="https://s.tradingview.com/widgetembed/?hideideas=1&overrides=%7B%7D&enabled_features=%5B%5D&disabled_features=%5B%5D&locale=en#%7B%22symbol%22%3A%22NASDAQ%3AAAPL%22%2C%22frameElementId%22%3A%22tradingview_db658%22%2C%22interval%22%3A%221%22%2C%22hide_side_toolbar%22%3A%220%22%2C%22allow_symbol_change%22%3A%221%22%2C%22save_image%22%3A%221%22%2C%22details%22%3A%221%22%2C%22studies%22%3A%22AwesomeOscillator%40tv-basicstudies%5Cu001fMACD%40tv-basicstudies%22%2C%22theme%22%3A%22dark%22%2C%22style%22%3A%221%22%2C%22timezone%22%3A%22America%2FNew_York%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22tradingfxcapital.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22NASDAQ%3AAAPL%22%2C%22page-uri%22%3A%22tradingfxcapital.com%2FDashboard%2Fhome%22%7D"
                      style={{
                        width: "100%",
                        height: "100%",
                        margin: "0px !important",
                        padding: "0px !important",
                      }}
                    />
                  </div>
                </div>
              </div>
              {/* TradingView Widget END */}
            </div>
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <div className="col-6">
                  <button
                    type="button"
                    onClick={() => {
                      const walletAddress =
                        document.getElementById("referral_link").value;
                      navigator.clipboard
                        .writeText(walletAddress)
                        .then(() => {
                          Swal.fire({
                            icon: "success",
                            title: "Copied!",
                            text: "referral link copied to clipboard!",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        })
                        .catch(() => {
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Failed to copy referral link.",
                            timer: 2000,
                            showConfirmButton: false,
                          });
                        });
                    }}
                    className="btn btn-primary"
                  >
                    Copy Referral Link
                  </button>
                </div>
                <div className="col-10">
                  <input
                    type="text"
                    style={{ color: "black" }}
                    className="form-control"
                    id="referral_link"
                    defaultValue={`https://www.finance-tellars.com/register.html?${userdetails.myReferralId}`}
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* TradingView Widget BEGIN */}
            <div
              className="tradingview-widget-container"
              style={{ width: "100%", height: "74px" }}
            >
              <iframe
                scrolling="no"
                allowTransparency="true"
                frameBorder={0}
                src="https://www.tradingview-widget.com/embed-widget/ticker-tape/?locale=en#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22Nasdaq%20100%22%7D%2C%7B%22proName%22%3A%22FX_IDC%3AEURUSD%22%2C%22title%22%3A%22EUR%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3ABTCUSD%22%2C%22title%22%3A%22BTC%2FUSD%22%7D%2C%7B%22proName%22%3A%22BITSTAMP%3AETHUSD%22%2C%22title%22%3A%22ETH%2FUSD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Afalse%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A46%2C%22utm_source%22%3A%22tradingfxcapital.com%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%2C%22page-uri%22%3A%22tradingfxcapital.com%2FDashboard%2Fhome%22%7D"
                title="ticker tape TradingView widget"
                lang="en"
                style={{
                  userSelect: "none",
                  boxSizing: "border-box",
                  display: "block",
                  height: "74px",
                  width: "100%",
                }}
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    '\n              .tradingview-widget-copyright {\n                font-size: 13px !important;\n                line-height: 32px !important;\n                text-align: center !important;\n                vertical-align: middle !important;\n                /* @mixin sf-pro-display-font; */\n                font-family: -apple-system, BlinkMacSystemFont, "Trebuchet MS",\n                  Roboto, Ubuntu, sans-serif !important;\n                color: #b2b5be !important;\n              }\n\n              .tradingview-widget-copyright .blue-text {\n                color: #2962ff !important;\n              }\n\n              .tradingview-widget-copyright a {\n                text-decoration: none !important;\n                color: #b2b5be !important;\n              }\n\n              .tradingview-widget-copyright a:visited {\n                color: #b2b5be !important;\n              }\n\n              .tradingview-widget-copyright a:hover .blue-text {\n                color: #1e53e5 !important;\n              }\n\n              .tradingview-widget-copyright a:active .blue-text {\n                color: #1848cc !important;\n              }\n\n              .tradingview-widget-copyright a:visited .blue-text {\n                color: #2962ff !important;\n              }\n            ',
                }}
              />
            </div>
            {/* TradingView Widget END */}
            <style
              dangerouslySetInnerHTML={{
                __html:
                  "\n            .mgm {\n              border-radius: 7px;\n              position: fixed;\n              z-index: 90;\n              bottom: 120px;\n              right: 20px;\n              background: #fff;\n              border: 4px solid #3f48cc;\n              padding: 10px 27px;\n              box-shadow: 0px 5px 13px 0px rgba(0, 0, 0, 0.3);\n            }\n\n            .mgm a {\n              font-weight: 700;\n              display: block;\n              color: #3f48cc;\n            }\n\n            .mgm a,\n            .mgm a:active {\n              transition: all 0.2s ease;\n              color: #3f48cc;\n            }\n          ",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
